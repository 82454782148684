import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../main.js";
import { createRouter, createWebHistory } from "vue-router";

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: "/", component: () => import("../views/HomeView.vue") },
        { 
            path: "/register/:subType?", 
            component: () => import("../views/RegisterView.vue"),
            props: true
        },
        { path: "/pricing", component: () => import("../views/PricingView.vue") },
        { path: "/faq", component: () => import("../views/FAQView.vue") },
        { path: "/solutions", component: () => import("../views/SolutionsView.vue") },
        { path: "/sign-in", component: () => import("../views/SignIn.vue") },
        { 
            path: "/maps", 
            component: () => import("../views/MapsView.vue"),
            meta: {
                requiresAuth: true
            }
        },
        { 
            path: "/userprofile", 
            component: () => import("../views/UserProfile.vue"),
            meta: {
                requiresAuth: true
            }
        },
        { 
            path: "/managegroup", 
            component: () => import("../views/ManageGroup.vue"),
            meta: {
                requiresAuth: true
            }
        },
        { 
            name: "hudview",
            path: "/hudview/:siteId/:sharedComponent?", 
            component: () => import("../views/HUDView.vue"),
            meta: {
                requiresAuth: true
            },
            props: true
        },
        { 
            name: "pubview",
            path: "/pubview/:siteId/:sharedComponent?", 
            component: () => import("../views/HUDView.vue"),
            props: true,
            beforeEnter: (to, from, next) => {
                const siteId = to.params.siteId;
                // Check if site doc exists
                getDoc(doc(db, 'publicSites', siteId))
                  .then(docSnap => {
                    if (docSnap.data()) {
                      // Site exists, allow navigation
                      next();
                    } else {
                      // Site not found, redirect to home page
                      next('/');
                    }
                  })
                  .catch(error => {
                    // Handle the error if the Firebase check fails
                    console.error("Error checking site existence:", error);
                    // You might want to redirect to an error page here
                    next();
                  });
            },
        },
        { path: "/error", component: () => import("../views/ErrorView.vue") },
        { path: "/forgotpassword", component: () => import("../views/ForgotPassword.vue") },
    ]
});

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const removeListener = onAuthStateChanged(
            getAuth(),
            (user) => {
                removeListener();
                resolve(user);
            },
            reject
        )
    })
}

router.beforeEach(async(to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (await getCurrentUser()) {
            next();
        } else {
            alert("you don't have access!")
            next("/");
        }
    } else {
        next();
    }
});

export default router;
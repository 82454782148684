<template>
  <nav class="bottom-pad-20px">
    <router-link class="left-align left-pad-5px right-pad-5px" v-if="isLoggedOut" to="/"><el-link type="info" v-bind:underline="false">Mapient</el-link></router-link>
    <router-link class="left-align left-pad-5px right-pad-5px" v-if="isLoggedOut" to="/solutions"><el-link type="info" v-bind:underline="false">Solutions</el-link></router-link>
    <router-link class="left-align left-pad-5px right-pad-5px" v-if="isLoggedOut" to="/pricing"><el-link type="info" v-bind:underline="false">Pricing</el-link></router-link>
    <router-link class="left-align left-pad-5px right-pad-5px" v-if="isLoggedOut" to="/faq"><el-link type="info" v-bind:underline="false">FAQ</el-link></router-link>
    <router-link class="left-align left-pad-5px right-pad-5px above" v-if="isLoggedIn" to="/maps"><el-link type="info" v-bind:underline="false">Maps</el-link></router-link>
    <router-link class="right-align left-pad-5px right-pad-5px" v-if="isLoggedOut" to="/register/Free"><el-link type="info" v-bind:underline="false">Register</el-link></router-link>
    <router-link class="right-align left-pad-5px right-pad-5px" v-if="isLoggedOut" to="/sign-in"><el-link type="info" v-bind:underline="false">Login</el-link></router-link>
    <el-button text class="right-align" title="Sign Out" v-if="isLoggedIn" @click="handleSignOut" :icon="switchButtonIcon" />
    <el-button text class= "right-align" title="Profile" v-if="isLoggedIn" @click="goToUserProfile" :icon="userFilledIcon" />
    <el-button text class= "right-align" title="Manage Group" v-if="isLoggedIn && isAdmin" @click="goToManageGroup" :icon="setupIcon" />
  </nav>
  <router-view />
</template>

<script>
import { onMounted, ref, shallowRef } from "vue";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { UserFilled, SwitchButton, SetUp } from '@element-plus/icons-vue';
import { doc, getDoc } from 'firebase/firestore';
import { db } from "./main.js";

const isLoggedIn = ref(false);
const isLoggedOut = ref(true);
const isAdmin = ref();
const switchButtonIcon = shallowRef(SwitchButton);
const userFilledIcon = shallowRef(UserFilled);
const setupIcon = shallowRef(SetUp);

let auth;

const setAdminStatus = (uid) => {
  const userDocRef = doc(db, "users", uid);
  getDoc(userDocRef).then(docSnap => {
    if (docSnap.exists() && docSnap.data() && docSnap.data().group) {
        // get user's group
        //group.value = docSnap.data().group;
        // check whether user is an superadmin
        //superadmin.value = docSnap.data().superadmin;
        // check whether user is an admin
        isAdmin.value = docSnap.data().admin;
    }
  return false;
  });
}

export default {
  data() {
    return {
      isLoggedIn,
      isLoggedOut,
      isAdmin,
      userFilledIcon,
      switchButtonIcon,
      setupIcon
    }
  },
  setup() {
    onMounted(() => {
      auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setAdminStatus(user.uid);
          isLoggedIn.value = true;
          isLoggedOut.value = false;
        } else {
          isLoggedIn.value = false;
          isLoggedOut.value = true;
        }
      });
    });
  },
  methods: {
    handleSignOut() {
      signOut(auth).then(() => {
        this.$router.push("/");
      });
    },
    goToUserProfile() {
      this.$router.push("/userprofile");
    },
    goToManageGroup() {
      this.$router.push("/managegroup");
    }
}


};

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #dfe4ea;
  background-color: #202225;
}

body {
  margin: 10;
  color: #dfe4ea;
  background-color: #202225;
}

a:link {
  color: #dfe4ea;
}

a:visited {
  color: #dfe4ea;
}

.center {
  text-align: center;
}

.left-align {
  float: left;
}

.right-align {
  float: right;
}

.left-pad-5px {
  padding-left: 5px;
}

.right-pad-5px {
  padding-right: 5px;
}

.bottom-pad-20px {
  padding-bottom: 20px;
}

.above {
  z-index: 1;
  position: fixed;
}

/*
.overlay {
    z-index: 1;
    position: fixed;
}

.sites-fixed {
  position: fixed;
  left: 70px;
}
*/
.left-margin-5px {
  margin-left: 5px;
}

.feature-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.top-bottom-margin-60px {
    margin-bottom: 60px;
    margin-top: 60px;
}

.top-margin-60px {
  margin-top: 60px;
}

.top-bottom-margin-20px {
    margin-bottom: 20px;
    margin-top: 20px;
}

.top-bottom-margin-10px {
    margin-bottom: 10px;
    margin-top: 10px;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.margin-top-0 {
    margin-top: 0;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}
</style>

import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue'
import router from './router'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDNDHEnlNoaMO05x0-_Jg6sQVIOZPZe6K4",
  authDomain: "mapient.firebaseapp.com",
  projectId: "mapient",
  storageBucket: "mapient.appspot.com",
  messagingSenderId: "552753706984",
  appId: "1:552753706984:web:27647bacdba9092d91965d",
  measurementId: "G-71JTEYK817"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
//const analytics = getAnalytics(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(firebaseApp);

const app = createApp(App)
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// Global error handler
app.config.errorHandler = (error, instance, info) => {
  logEvent(analytics, "Error", {
      error: error,
      instance: instance,
      info: info
  });
  router.push("/error");
};

window.onerror = function(message, source, line, column, error) {
  logEvent(analytics, "Error", {
      message: message,
      source: source,
      line: line,
      column: column,
      error: error
  });
  router.push("/error");
  return true;
}

/*
self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
initializeAppCheck(app, { 
  provider: new ReCaptchaV3Provider('6LfatxYpAAAAABXPuceWi1iy9enSHPz5jbB-Vosj'),
    
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true 
});
*/
app.use(router)
app.mount('#app')